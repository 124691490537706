import store from '../../store/index.js';

export default async function loadSettingsAll(to, from, next) {
	
	const settingsLoaded = store.getters['settings/getLoadedAll'];
	
	if (settingsLoaded)
		next();
	else {
		store.dispatch('settings/loadSettingsAll').finally(() => {
			next();
		});
	}
}