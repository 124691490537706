<template>
    <v-card outlined :max-height="!mobile ? '130px' : '120px'" :max-width="!mobile ? '240px' : '200px'" width="100%"
        style="border-radius: 5px; border: 1px solid;" class="pa-2">
        <v-row justify="space-between" class="ma-0">
            <v-col cols="auto" class="pa-0">
                <v-img v-if="iconMap[card.brand] && iconMap[card.brand].icon" contain :src="iconMap[card.brand].icon"
                    :width="mobile ? 35 : 55" style="float:right;" class="mt-1 mb-1" />
                <v-icon v-else style="font-size: 30px" :style="{ 'width': mobile ? '40px' : '60px' }">
                    mdi-credit-card
                </v-icon>
            </v-col>
            <v-col cols="auto" class="pa-0" align-self="center">
                <span v-if="iconMap[card.brand] && iconMap[card.brand].name" v-text="iconMap[card.brand].name"
                    class="font-weight-bold" :class="!mobile ? 'text-h6' : 'text-subtitle-1'"></span>
                <span v-else v-text="card.brand" style="text-transform: capitalize" class="font-weight-bold"
                    :class="!mobile ? 'text-h6' : 'text-subtitle-1'"></span>
            </v-col>
        </v-row>

        <v-spacer :style="!mobile ? 'height: 25px' : 'height: 15px'" />
        <v-row class="ma-0 text-subtitle-2">
            <v-col cols="12" class="pa-0">
                <span v-text="'**** **** ****' + ' ' + card.last4"></span>
            </v-col>
            <v-col cols="12" class="pa-0">
                <span v-text="card.expiryMonth + '/' + card.expiryYear"></span>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import visa from 'payment-icons/min/flat/visa.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import americanExpress from 'payment-icons/min/flat/amex.svg';
import etransfer from '@/assets/payment-icons/etransfer.png';

export default {
    name: "Cards",
    components: {

    },
    mixins: [],
    props: {
        card: {
            type: Object,
            required: true,
        }
    },
    data: function () {
        return {
            iconMap: {
                'visa': {
                    icon: visa,
                    name: "Visa",
                    text: "VISA"
                },
                'mastercard': {
                    icon: mastercard,
                    name: "Mastercard",
                    text: "MASTERCARD"
                },
                'amex': {
                    icon: americanExpress,
                    name: "American Express",
                    text: "AMERICAN_EXPRESS"
                },
                'ElectronicTransfer': {
                    icon: etransfer,
                    name: "Electronic Transfer",
                    text: "ETRANSFER"
                },
            }
        }
    },

    computed: {
        mobile() {
            return this.$vuetify.breakpoint.mobile
        },
        cellphone() {
            return this.$vuetify.breakpoint.xs
        },

    },
    watch: {

    },
    methods: {

    },
    created: function () {

    },
    mounted: function () {

    },

}
</script>

<style scoped></style>

<style lang="scss" scoped>
@import './node_modules/vuetify/src/styles/settings/variables';
@import "src/globals";
</style>