export default {
	namespaced: true,
	state: {},
	actions: {},
	getters: {
		itemHeight() {
			return ($vuetify) => {
				if ($vuetify.breakpoint.lg) {
					return 300;
				}
				if ($vuetify.breakpoint.xl) {
					return 360;
				} else if ($vuetify.breakpoint.mdAndDown) {
					return '100%';
				}
			}
		},
	},
	mutations: {}
};
