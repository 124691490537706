<template>
	<v-combobox no-filter :class="searchClasses" :value="value" ref="searchBox" :allow-overflow="false" :items="results"
		:loading="loading" :search-input.sync="query" item-text="description" item-value="place_id" v-bind="$attrs"
		@focus="clearResults" @input="handleInput" @blur="handleBlur">
		<template v-slot:item="{ item }">
			<v-list-item-content v-if="item">
				<v-list-item-title v-html="item.structured_formatting.main_text" />
				<v-list-item-subtitle v-html="item.structured_formatting.secondary_text" />
			</v-list-item-content>
		</template>

		<template v-slot:selection="{ item }">
			<span class="justify-center ml-6 text-center align-center" style="width: 100%;"
				:style="{ 'font-size': mobile ? '16px' : '18px' }" v-if="item">
				{{ isObject(item) ? item.description : item }}
			</span>
		</template>

		<template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
			<slot :name="name" v-bind="data"></slot>
		</template>

		<template v-slot:append v-if="showGeolocationButton">
			<v-tooltip color="#0b4073" nudge-top="5" top>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-if="parentName !== 'search-brokerage'" color="#0b4073" icon large v-bind="attrs"
						@click.stop="getGeolocation" v-on="on">
						<v-icon size="25">near_me</v-icon>
					</v-btn>
				</template>
				<span style="font-size: 1.4em;">Use my current location</span>
			</v-tooltip>
		</template>
	</v-combobox>
</template>

<script>
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";

export default {
	name: "LocationAutocomplete",
	props: {
		parentName: {
			required: false,
			default: "",
		},
		rounded: {
			type: Boolean,
			default: true
		},
		debounceMsec: {
			type: Number,
			default: 200,
		},
		placesFields: {
			type: Array,
			default: () => [
				"geometry.location",
				"address_components",
				"formatted_address",
			],
		},
		placesTypes: {
			type: Array,
			default: () => ["(cities)"],
		},
		countries: {
			type: Array,
			default: () => ["can", "aus", "usa"],
		},
		size: {
			type: String,
			default: "small",
		},
		disallowedTypes: {
			type: Array,
			default: () => [
				"postal_code",
				"route",
				"administrative_area_level_1",
				"premise",
				"colloquial_area",
				"street_address",
			],
		},
		value: {}
	},
	data() {
		return {
			platform: null,
			searchService: null,
			placesService: null,
			geocoderService: null,
			query: "",
			results: [],
			loading: false,
			googleSessionToken: "",
			place: "",
			showGeolocationButton: false
		};
	},
	watch: {
		query(val) {
			this.search(val);
		}
	},
	created() {
		this.search = debounce(this.search, this.debounceMsec);
	},
	mounted() {
		this.initService();
		this.$store.dispatch('location/checkGeolocationEnabled').then(enabled => {
			this.showGeolocationButton = enabled;
		})
	},
	methods: {
		isObject,
		getGeolocation() {
			this.$getLocation()
				.then((coordinates) => {
					this.geocoderService.geocode({ location: coordinates }).then((r) => {
						let validResults = r.results.filter((r) => {
							return (
								r.types.indexOf("locality") !== -1 &&
								r.types.indexOf("political") !== -1
							);
						});

						if (validResults.length === 0)
							this.$toasted.show(
								"Unable to locate the nearest city using your co-ordinates."
							);
						let result = validResults[0];

						this.$emit("placechanged", result);
						this.handleInput(result);

						this.place = result.formatted_address;
						this.$refs.searchBox.blur();
					});
				})
				.catch(() => {
					this.$toasted.error("Location services unavailable.");
				});
		},

		initService() {
			this.searchService = new window.google.maps.places.AutocompleteService();
			this.placesService = new window.google.maps.places.PlacesService(
				new window.google.maps.Map(document.createElement("div"))
			);
			this.geocoderService = new window.google.maps.Geocoder();
			this.generateNewSessionToken();

		},
		async search(val) {
			if (isEmpty(val) || val.length < 3) {
				this.results = [];
				return;
			}
			this.loading = true;
			try {
				this.results = await this.searchPlace(val);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.warn("Could not retrieve location suggestions", e);
			}
			this.loading = false;
		},
		searchPlace(val) {
			return new Promise((resolve, reject) => {
				this.searchService.getPlacePredictions(
					{
						input: val,
						types: this.placesTypes,
						sessionToken: this.googleSessionToken,
						componentRestrictions: {
							country: this.countries,
						},
					},
					(predictions, status) => {
						if (status === window.google.maps.places.PlacesServiceStatus.OK) {
							return resolve(
								predictions
									.filter((p) => {
										let primary = p.types[0];
										return this.disallowedTypes.indexOf(primary) === -1;
									})
									.map((r) => {
										r.description = r.description
											.normalize("NFD")
											.replace(/[\u0300-\u036f]/g, "");
										return r;
									})
							);
						}
						if (
							status ===
							window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
						) {
							return resolve([]);
						}
						return reject(status);
					}
				);
			});
		},
		generateNewSessionToken() {
			this.googleSessionToken = new window.google.maps.places.AutocompleteSessionToken();
		},
		clearResults: function () {
			this.handleInput(null);
		},
		async handleInput(choice) {

			if (choice == null) {
				this.place = null;
				this.$emit('placechanged', null);
				return;
			}
			// If choice is a string, simply return that
			if (!isObject(choice)) {
				this.$emit("input", choice);
				return;
			}
			// Already set the text to the current information as provided by the choice
			this.$emit("input", choice.description);
			this.loading = true;
			try {
				// Attempt to get full address including postal code
				const placeDetails = await this.getPlaceDetails(choice);
				let addr = this.$helpers.extractAddress(placeDetails);
				placeDetails.formatted_address = addr;

				this.$emit("placechanged", placeDetails);
				this.$emit("input", addr);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}
			this.loading = false;
			this.generateNewSessionToken();
			this.$refs.searchBox.blur()
		},
		getPlaceDetails(choice) {
			return new Promise((resolve, reject) => {
				this.placesService.getDetails(
					{
						placeId: choice.place_id,
						sessionToken: this.googleSessionToken,
						fields: this.placesFields,
					},
					(result, status) => {
						if (status === window.google.maps.places.PlacesServiceStatus.OK) {
							return resolve(result);
						}
						return reject(result);
					}
				);
			});
		},
		handleBlur() {
			this.$emit('handleLocationBlur');
		},
	},
	computed: {
		cellphone() {
			return this.$vuetify.breakpoint.xs
		},
		mobile() {
			return this.$vuetify.breakpoint.mobile
		},
		searchClasses: function () {
			return {
				'search': this.rounded,
				'search-mobile': this.$vuetify.breakpoint.mobile,
				'align-center': true
			}
		}
	}
};
</script>

<style scoped>
.align-center>>>input {
	text-align: center;
}
</style>

<style>
.search {
	border-radius: 35px;
}

.search.search-mobile {
	border-radius: 35px;
}

.display-none {
	display: none;
}

.v-text-field.search-padded {
	padding-top: 0;
	border-left-width: 0;
	border-top-width: 0;
	border-bottom-width: 0;
	border-right-width: 1px;
	border-style: solid;
	margin-left: 8px;
}

.v-text-field--box .v-input__slot,
.v-text-field--outline .v-input__slot {
	min-height: auto !important;
	display: flex !important;
	align-items: center !important;
}

.BrokerageByCityContainer .v-input__append-inner {
	padding-right: 25px;
}

@media only screen and (max-width: 1263px) {
	.BrokerageByCityContainer .v-input__append-inner {
		padding-right: 0px;
	}
}
</style>
