import axios from 'axios';
import router from '@/router/index.js'

export default {
	namespaced: true,
	actions: {
		/**
		 * Uploads one or more images
		 * @param ctx
		 * @param fileList
		 * @param options
		 * @returns {Promise<* | []>}
		 */
		async uploadImages(ctx, {fileList, options}) {
			let form = new FormData();
			
			// Autobox into an array if it isnt already
			if (!Array.isArray(fileList))
				fileList = [fileList];
			
			// Append each file.
			fileList.forEach(file => {
				form.append('images', file);
			});
			
			return axios(Object.assign({
				url: '/api/v1/media/image',
				method: 'post',
				data: form,
				headers: {'Content-Type': 'multipart/form-data'}
			}, options)).then(response => {
				if (response.status === 200)
					return response.data;
				else
					return [];
			}).catch(error => {
				if (error.response.status === 401)
					router.push({path: '/dashboard/login'})
				return error;
			})
		}
	}
}