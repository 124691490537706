/* eslint-disable */

import axios from "axios";
import Vue from "vue";

export default {
	namespaced: true,

	state: {
		standardPlans: [],
		enterprisePlans: {},
	},

	mutations: {
		setStandardPlans(state, standardPlans) {
			Vue.set(state, 'standardPlans', standardPlans);
		},

		setEnterprisePlans(state, enterprisePlans) {
			Vue.set(state, 'enterprisePlans', enterprisePlans);
		},
	},

	getters: {
		getStandardPlans: function (state) {
			return state.standardPlans;
		},

		getEnterprisePlans: function (state) {
			return state.enterprisePlans;
		},

		getEnterprisePlan: function (state) {
			return (planId) => {
				return state.enterprisePlans.content.find((plan) => {
					return plan.id === planId;
				})
			};
		},
	},

	actions: {
		async obtainStandardPlans(ctx) {
			return axios.get(`/api/v1/license/plans`).then((res) => {
				ctx.commit('setStandardPlans', res.data);
				return res
			});
		},

		async getStandardPlan(ctx, { planId }) {
			return ctx.dispatch('obtainStandardPlans')
				.then((response) => {
					return response.data.find((plan) => {
						return plan.id === planId
					});
				});
		},

		async updateStandardPlan(ctx, {
			id,
			nickname,
			price,
			seat_count,
			note,
		}) {
			return axios.put(`/api/v1/license/plan/${id}`, {
				nickname,
				price,
				seat_count,
				note,
			});
		},

		/** Enterprise */
		async obtainEnterprisePlans(ctx, {
			page = 1,
			perPage = 999,
		} = {}) {

			let start = page - 1;

			return axios.get(`/api/v1/license/enterprise/plans?start=${start}&size=${perPage}`)
				.then((res) => {
					ctx.commit('setEnterprisePlans', res.data)
					return res;
				});
		},

		async getEnterprisePlan(ctx, { planId }) {
			return ctx.dispatch('obtainEnterprisePlans')
				.then((response) => {
					return response.data.content.find((plan) => {
						return plan.id === planId
					});
				});
		},

		/**
		 *
		 * @param ctx
		 * @param {String} id
		 * @param {String} nickname
		 * @param {String} license_class
		 * @param {String} license_type
		 * @param {Number} price
		 * @param {Number} seat_count
		 * @returns {Promise<AxiosResponse<any>>}
		 */
		async createEnterprisePlan(ctx, {
			id,
			nickname,
			price,
			seat_count,
			license_class,
			license_type,
		}) {
			return axios.post(`/api/v1/license/plan`, {
				id,
				nickname,
				price,
				seat_count,
				license_class,
				license_type,
			});
		},

		async deleteLicensePlan(ctx, { planId }) {
			return axios.delete(`/api/v1/license/plan/${planId}`);
		},

		async getPlanByPlanId(ctx, { planId }) {
			return axios.get(`/api/v1/license/plan/${planId}`);
		},

		/** Subscribe User to the Plan */

		async subscribeToStandardPlan(ctx, {
			merchantId,
			coupon,
			tier,
			payment_method

		}) {
			return axios.post(`/api/v1/license/${merchantId}/subscribe`, {
				// custom_code: null,
				coupon: coupon,
				tier: tier,
				payment_method: payment_method,
			});
		},

		async subscribeToEnterprisePlan(ctx, {
			merchantId,
			custom_code,
			coupon,
			payment_method
		}) {
			return axios.post(`/api/v1/license/${merchantId}/subscribe`, {
				custom_code: custom_code,
				coupon: coupon,
				// tier: 'ENTERPRISE',
				payment_method: payment_method,
			});
		},

		/** get subscription info by the uuid (not by ListingId/merchantId) for current User */
		async obtainLicense(ctx, { licenseUuid }) {
			return axios.get(`/api/v1/license/${licenseUuid}/`);
		},

		/** get all licenses for current user */
		async obtainAllLicenses() {
			return axios.get(`/api/v1/license/`)
				.then((response) => {
					return response.data;
				});
		},

		async obtainLicenseForListing(ctx, { listingId }) {
			return this.dispatch('api/license/obtainAllLicenses')
				.then((data) => {
					console.log('obtainLicenseForListing data = ', data)
					console.log('obtainLicenseForListing listingId = ', listingId)
					return data.find((license) => {

						console.log('obtainLicenseForListing = ', license.licensee === listingId)
						return license.licensee === listingId;
					});
				})
		},


		// attach the billboard to the license
		async addBillboardToLicense(ctx, { licenseUuid, billboardId }) {
			return axios.post(`/api/v1/license/${licenseUuid}/${billboardId}`);
		},

		// attach the billboards to the license
		async addBillboardsToLicense(ctx, { licenseUuid, billboardsIds }) {
			return axios.post(`/api/v1/license/${licenseUuid}/seats`, {
				// seats: billboardsIds
				seats: billboardsIds
			});

			// return axios.post(`/api/v1/license/${licenseUuid}/`, {
			//     // seats: billboardsIds
			//     seats: billboardsIds
			// });
		},


		// obtain all listings attached to the billboard
		async obtainAttachedListings(ctx, { billboardId, end = 9999 }) {
			return axios.get(`/api/v1/license/members/${billboardId}/?start=0&end=${end}`);
		},

		// Preview a License change request.
		// /{merchantId}/preview/subscribe
		async licenceChargePreview(ctx, {
			listingId,
			custom_code,
			coupon,
			tier,
			payment_method,
		}) {
			return axios.post(`/api/v1/license/${listingId}/preview/subscribe`, {
				custom_code,
				coupon,
				// tier,
				payment_method,
			});
		},

		// delete license/subscription
		async deleteSubscription(ctx, {
			licenseUuid,
		}) {
			return axios.delete(`/api/v1/license/${licenseUuid}`);
		},


		// delete a board from the specific license/subscription
		async deleteSubscriptionBoard(ctx, {
			licenseUuid,
			billboardId,
		}) {
			return axios.delete(`/api/v1/license/${licenseUuid}/${billboardId}`);
		},

		async getHistoryByInvoiceNumber(ctx, {
			invoiceNumber
		}) {
			return axios.post('/api/v1/license/payments/history', {
				after: null,
				size: 100,
			})
				.then((res) => {
					return res.data.find((item) => {
						return item.number === invoiceNumber;
					});
				})
		},

		async getPaymentsHistory(ctx, {
			after,
			size,
		}) {
			return axios.post('/api/v1/license/payments/history', {
				after: after,
				size: size,
			})
				.then((res) => {
					return res;
				})
		},

	},
};