<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: "AdministratorView"
}
</script>

<style scoped>

</style>