import axios from "axios";
import router from '@/router/index';
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		user: {},
		cart: {
			items: []
		},
		loggedin: false,
		firstLoad: true,
		loadingFromLogin: false,
		stripeAuthenticated: true
	},
	actions: {
		async getUser({ commit, dispatch }) {
			return axios.get('/api/v1/user/').then(result => {
				commit('setUser', result.data);
				commit('setLoggedin', true)
				commit('setFirstLoad', false);
				dispatch('stripeAuthStatus')
				return result.data;
			}).catch(() => {
				commit("setLoggedin", false)
				commit('setFirstLoad', false);
			});
		},

		async getUserById(ctx, { userId }) {
			return axios.get(`/api/v1/user/${userId}`)
				.then((res) => {
					return res;
				})
				.catch(() => {

				});
		},

		async login(ctx, { payload, extra }) {
			return axios.post('/login', payload, extra).then(result => {
				return result;
			}).catch((e) => {
				Vue.toasted.show('Unable to log you in.')
				throw e;
			});
		},
		async logout({ commit }) {
			return axios.post('/logout').then(() => {
				commit("setUser", null);
				commit('setLoggedin', false);
				Vue.toasted.show('Logout successful', { icon: { name: 'check', after: true }, duration: 2000 })
				router.push('/dashboard/login');
			});
		},

		async search(ctx, {
			firstName = null,
			fullName = null,
			lastName = null,
			phone = null,
			username = null,
			start = 0,
			size = 12,
		}) {
			return axios.post('/api/v1/user/search', {
				firstName,
				fullName,
				lastName,
				phone,
				username,
				start,
				size,
			});
		},

		async stripeAuthStatus(ctx) {
			return axios.get('/api/v1/stripe/oauth/connected').then(result => {
				ctx.commit('setStripeAuthenticated', result.data.connected);
				return result;
			});
		},
		async stripeAuthAccounts(ctx) {
			return axios.get('/api/v1/stripe/oauth/accounts').then(result => {
				return result;
			});
		},
	},
	getters: {
		getStripeAuthenticated(state) {
			return state.stripeAuthenticated
		},
		getUser(state) {
			return state.user;
		},
		getLoggedIn(state) {
			return state.loggedin;
		},
		getCart(state) {
			return state.cart;
		},
		isFirstLoad(state) {
			return state.firstLoad;
		},

		isPostingAllowed(state) {
			return state.user
				// && state.user.phone
				&& state.user.billing
				&& state.user.billing.countryCode
				&& state.user.billing.postalCode
				&& state.user.billing.state
				&& state.user.billing.city
				&& state.user.billing.line1
		}
	},
	mutations: {
		setStripeAuthenticated(state, payload) {
			state.stripeAuthenticated = payload;
		},
		setUser(state, payload) {
			state.user = payload;
		},
		clearUser(state) {
			state.user = null;
		},
		setLoggedin(state, payload) {
			state.loggedin = payload;
		},
		setFirstLoad(state, payload) {
			state.firstLoad = payload;
		}
	}
};
