import axios from "axios";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
    
    },
    actions: {
        async getOrders(ctx, { page = 0, size = 15 }) {
            return axios.get(`/api/v1/marketplace/order/?page=${page}&size=${size}`);
        },
        async getOrder(ctx, { orderId }) {
            return axios.get(`/api/v1/marketplace/order/${orderId}`);
        },

    },
    getters: {

    },
    mutations: {

    }
};
