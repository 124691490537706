import axios from 'axios';
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		settings: {
			articles: []
		},
		loaded: false,
		
		settingsAll: null,
		loadedAll: false,
	},
	actions: {
		loadSettings({commit}) {
			return axios.get('/api/v1/management/homepage/').then(r => {
				commit('setSettings', r.data);
				return r;
			}).catch(e => {
				Vue.$toasted.error(e);
			})
		},
		
		loadSettingsAll({commit}) {
			return axios.get('/api/v1/management/homepage/all').then(r => {
				commit('setSettingsAll', r.data);
				return r;
			}).catch(e => {
				Vue.$toasted.error(e);
			})
		},
	},
	getters: {
		getLoaded(state) {
			return state.loaded;
		},
		getSettings(state) {
			return state.settings;
		},
		
		getSettingsAll(state) {
			return state.settingsAll;
		},
		
		getLoadedAll(state) {
			return state.loadedAll;
		},
	},
	mutations: {
		setSettings(state, value) {
			state.settings = value;
			state.loaded = value != null;
		},
		
		setSettingsAll(state, value) {
			state.settingsAll = value;
			state.loadedAll = value != null;
		},
	}
}