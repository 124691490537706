import axios from 'axios';
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		subscriptions: {},
		newSubscriptions: {}
	},
	actions: {
		getExistingSubscriptions: async function (ctx) {
			return axios.get(`/api/v1/billboard/subscriptions`).then(r => {
				if (r.status !== 200)
					return {};
				ctx.commit('setSubscriptions', r.data);
			});
		},
		submitSubscription: async function (ctx, merchant) {
			let payload = {
				subscriptions: ctx.getters.requestedSubscriptions[merchant].map(s => {
					return {id: s}
				})
			}
			
			let coupon = ctx.rootGetters['price/coupon'];
			
			if (coupon != null && coupon.id)
				payload.coupon = coupon.id;
			
			return axios.post(`/api/v1/stripe/merchant/${merchant}`, payload).then(r => {
				if (r.status === 200)
					ctx.commit('clearCart', merchant);
				
				// Lazy load the new subscriptions into the system
				ctx.dispatch('getExistingSubscriptions');
				
				return r;
			})
		},
		submitUnsubscription: async function (ctx, {merchant, billboards}) {
			
			// Autobox into an array
			if (!Array.isArray(billboards))
				billboards = [billboards];
			
			return axios.delete(`/api/v1/stripe/merchant/${merchant}`, {
				data: {
					billboards: billboards.map(b => b.id)
				}
			}).then((r) => {
				
				// Lazy load the new subscriptions into the system
				ctx.dispatch('getExistingSubscriptions');
				
				return r;
			})
		}
	},
	mutations: {
		setSubscriptions(state, subscriptions) {
			Object.keys(subscriptions).forEach(sub => {
				Vue.set(state.subscriptions, sub, subscriptions[sub]);
			});
		},
		clearCart(state, merchant) {
			state.newSubscriptions[merchant].splice(0, state.newSubscriptions[merchant].length);
		},
		setRequestedSubscriptions(state, {subscriptions, merchant}) {
			Vue.set(state.newSubscriptions, merchant, subscriptions);
		},
		addRequestedSubscription(state, {subscription, merchant}) {
			if (!(merchant in state.newSubscriptions))
				Vue.set(state.newSubscriptions, merchant, []);
			
			state.newSubscriptions[merchant].push(subscription);
		},
		removeRequestedSubscription(state, {subscription, merchant}) {
			let requestedSubs = state.newSubscriptions[merchant];
			
			if (requestedSubs) {
				let index = requestedSubs.indexOf(subscription);
				
				if (index >= 0)
					requestedSubs.splice(index, 1);
			}
		}
	},
	getters: {
		subscriptions: function (state) {
			return state.subscriptions;
		},
		requestedSubscriptions: function (state) {
			return state.newSubscriptions;
		}
	}
}