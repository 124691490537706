import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
export default {
	install(Vue) {
		Vue.helpers = this;
		let localUrl = 'http://localhost:8081';
		let devUrl = 'https://www.mybreb.dev';
		let prodUrl = 'https://www.bestrealestatebuy.com';
		let devImageUrl = 'https://cdn.mybreb.dev';
		let prodImageUrl = 'https://cdn.bestrealestatebuy.com';

		Vue.prototype.$helpers = {
			getImageSrcset: function (imageId) {
				let imageURL = this.getBaseImageURL();
				return `${imageURL}/thumbnails/${imageId}_360p 480w, ${imageURL}/thumbnails/${imageId}_720p 1280w, ${imageURL}/thumbnails/${imageId}_1080p 1920w`
			},
			getImageSizes: function () {
				return '(max-width: 480px) 480px, (max-width: 1280px) 1280px, 1920px';
			},
			formattedPhoneNumber(pnObject) {
				if (!pnObject)
					return "";

				let pn = parsePhoneNumber(`+${pnObject.code}${pnObject.number}`);
				return pn.formatInternational();
			},
			getBaseImageURL() {
				if (window.location.origin === prodUrl) {
					return `${prodImageUrl}`;
				} else {
					return `${devImageUrl}`;
				}
			},
			getThumbnailURL(imageId) {
				if (imageId === undefined || imageId === null)
					console.trace();

				if (window.location.origin === prodUrl ) {
					return `${prodImageUrl}/thumbnails/${imageId}_360p`;
				} else {
					return `${devImageUrl}/thumbnails/${imageId}_360p`;
				}
			},
			getImageURL: function (imageId) {
				if (imageId === undefined || imageId === null)
					console.trace();

				if (window.location.origin === prodUrl) {
					return `${prodImageUrl}/thumbnails/${imageId}_1080p`;
				} else {
					return `${devImageUrl}/thumbnails/${imageId}_1080p`
				}
			},
			getFullQualityURL: function (imageId) {
				if (window.location.origin === prodUrl) {
					return `${prodImageUrl}/images/${imageId}`;
				} else {
					return `${devImageUrl}/images/${imageId}`;
				}
			},
			transformImageIntoObject: function (file) {
				let url = URL.createObjectURL(file);

				return {
					name: file.name,
					image: file,
					url: url,
					dispose: function () {
						URL.revokeObjectURL(url);
					}
				}
			},

			sortListingImages: function (listing) {
				if (!listing || !listing.images) {
					return [];
				}
				let ret = [...listing.images].sort((item1, item2) => {
					if (item1 === listing.primaryImage) {
						return -1;
					}
					if (item2 === listing.primaryImage) {
						return 1;
					}
					return 0;
				});
				return ret
			},

			extractAddress: function (places) {
				let comps = places.address_components;
				let locality = '';
				let region = '';
				let country = '';
				let neighborhood = '';

				if (comps == null || comps.length === 0)
					return null;

				for (const component of comps) {
					let comp = component.types[0];

					switch (comp) {
						case 'sublocality_level_1':
						case 'neighborhood':
						case 'locality':
							neighborhood ||= component.long_name;
							break;
						case 'administrative_area_level_1':
							region = component.long_name;
							break;
						case 'country':
							country = component.long_name;
					}
				}

				return `${neighborhood ? neighborhood + ', ' : ''}${locality ? locality + ', ' : ''}${region}, ${country}`

			},
			mapInternalName(type) {
				return type;
			},
			extractSubcategory(listing) {
				return listing.subcategory;
			},

			listingStreet(listing) {
				let fullAddress = listing.address.fullAddress;
				if (typeof fullAddress !== "string") {
					return;
				}
				return fullAddress.split(',').map((item) => item.trim()).slice(0, 1).join('');
			},

			phoneStrToObj(phoneStr) {
				let countryPhoneCodes = this.getCountryPhoneCodes();
				let countryCode = Object.keys(countryPhoneCodes).find((key) => {
					let code = countryPhoneCodes[key];
					const re = new RegExp('^\\+' + code);
					return re.test(phoneStr)
				});

				if (!countryCode) {
					return {
						code: '+1',
						codeWoPlus: '1',
						number: phoneStr,
					}
				}

				let numberCode = countryPhoneCodes[countryCode];
				const re = new RegExp('^\\+' + numberCode);
				let phoneStrWoCode = phoneStr.replace(re, '');

				return {
					code: '+' + numberCode,
					codeWoPlus: numberCode,
					number: phoneStrWoCode,
				}
			},

			getCountryPhoneCodes() {
				return {
					'CA': '1',
					'US': '1',
					'AU': '61',
				};
			},

			/**
			 * @param {string|Object} phoneNumber
			 * @return {string}
			 */
			phoneObjToStr(phoneNumber) {
				if (typeof phoneNumber === 'string') {
					return phoneNumber;
				} else if (phoneNumber.code || phoneNumber.number) {
					let ret = '';
					if (phoneNumber.code) {
						ret += '+' + phoneNumber.code + ' ';
					}
					return ret + phoneNumber.number.toString().replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
				}
				return '';
			},

			routerPush($router, $route, newParams, name = null) {
				let oldParams = $route.query;

				if ((name === null || $route.name === name) && JSON.stringify(oldParams) === JSON.stringify(newParams)) {
					return;
				}

				let data = {
					query: newParams
				};

				if (name !== null) {
					data.name = name;
				}

				$router.push(data);
			},

			routerAppendGetParameters($router, $route, params, name = null) {
				let oldParams = $route.query;
				let newParams = Object.assign({}, oldParams, params);
				this.routerPush($router, $route, newParams, name)
			},

			priceFormat(price, currency = 'CAD', inCent = true, notation = 'standard', style = 'currency', maximumFractionDigits = 2, minimumFractionDigits = 2) {
				let formatedPrice = new Intl.NumberFormat('en-US', { maximumFractionDigits, minimumFractionDigits, notation, style, currency }).format(inCent? price / 100 : price);
				if (currency.toUpperCase() === 'AUD') {
					formatedPrice = formatedPrice.replace('A$', 'AU$');
				}
				if (currency.toUpperCase() === 'USD') {
					formatedPrice = formatedPrice.replace('$', 'US$');
				}
				return formatedPrice;
			},

			scrollToTop() {
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			},
			scrollTo(height) {
				window.scrollTo({
					top: height,
					behavior: 'smooth',
				});
			},
			scrollUp(height) {
				window.scrollTo({
					top: window.scrollY - height,
					behavior: 'smooth',
				});
			},
			getDefaultSearchingDistance(){
				return 160000;
			},

			// Group and Permissions Properties
			selfPermissions: function ($store) {
				return $store.getters['api/acl/getSelfPermissions'];
			},
			selfGroups: function ($store) {
				return $store.getters['api/acl/getSelfGroups'];
			},
			// Group
			isAdministratorGroup: function ($store) {
				if (!this.selfGroups($store) || this.selfGroups($store).length === 0) return false;
				return this.selfGroups($store).indexOf("administrators") >= 0;
			},

			// isMerchantGroup: function ($store) {
			// 	if (this.isAdministratorGroup($store)) return true;
			// 	return this.selfGroups($store).indexOf("merchants") >= 0;
			// },

			// isPartnerGroup: function ($store) {
			// 	if (this.isAdministratorGroup($store)) return true;
			// 	return this.selfPermissions($store).indexOf("partners") >= 0;
			// },

			// isCityAdministratorGroup: function ($store) {
			// 	if (this.isAdministratorGroup($store)) return true;
			// 	return this.selfPermissions($store).indexOf("city_administrators") >= 0;
			// },

			// isRealtorGroup: function ($store) {
			// 	if (this.isAdministratorGroup($store)) return true;
			// 	return this.selfPermissions($store).indexOf("realtors") >= 0;
			// },

			// Administrator Group Permissions
			hasAdministratorAdministration: function ($store) {
				return this.selfPermissions($store).some(item =>
					item.name === 'administrators' && 
					item.permissions.includes('administration')
				);
			},
			// Merchant Group Permissions
			hasMerchantCreate: function ($store) {
				if (this.isAdministratorGroup($store)) return true;
				return this.selfPermissions($store).some(item =>
					item.name === 'merchants' && 
					(item.permissions.includes('administration') || item.permissions.includes('create'))
				);
			},
			// Partner Group Permissions
			hasPartnerCreate: function ($store) {
				if (this.isAdministratorGroup($store)) return true;
				return this.selfPermissions($store).some(item =>
					item.name === 'partners' && 
					(item.permissions.includes('administration') || item.permissions.includes('create'))
				);
			},
			// City Group Permissions
			hasCityAdministration: function ($store) {
				if (this.isAdministratorGroup($store)) return true;
				return this.selfPermissions($store).some(item =>
					item.name === 'city_administrators' && 
					item.permissions.includes('administration')
				);
			},
			hasCityDelete: function ($store) {
				if (this.isAdministratorGroup($store)) return true;
				return this.selfPermissions($store).some(item =>
					item.name === 'city_administrators' && 
					(item.permissions.includes('administration') || item.permissions.includes('delete'))
				);
			},
			hasCityCreate: function ($store) {
				if (this.isAdministratorGroup($store)) return true;
				return this.selfPermissions($store).some(item =>
					item.name === 'city_administrators' && 
					(item.permissions.includes('administration') || item.permissions.includes('create'))
				);
			},
			hasCityWrite: function ($store) {
				if (this.isAdministratorGroup($store)) return true;
				return this.selfPermissions($store).some(item =>
					item.name === 'city_administrators' && 
					(item.permissions.includes('administration') || item.permissions.includes('create') || item.permissions.includes('write') )
				);
			},
			hasCityRead: function ($store) {
				if (this.isAdministratorGroup($store)) return true;
				return this.selfPermissions($store).some(item =>
					item.name === 'city_administrators' && 
					(item.permissions.includes('administration') || item.permissions.includes('read'))
				);
			},
		};
	}
}