<template>
	<v-footer color="primary" dark padless class="footer">
		<div class="footer-background-color"></div>

		<!-- REDACTED  -->
		<!-- <v-btn color="accent" width="100%" @click="scrollToTop">Back to Top</v-btn> -->
		<v-row dense class="footer-container">
			<!-- FIRST COLUMN -->
			<v-col class="footer-column logo" style=" max-width: 350px; z-index: 2;">
				<v-row class="footer-column-row">
					<v-col class="footer-logo-container">
						<router-link to="/">
							<img v-bind:src="BrebLogo" :width="$vuetify.breakpoint.mobile ? 200 : 300" />
						</router-link>
					</v-col>
				</v-row>
				<v-row class="footer-column-row">
					<v-col class="footer-title">Resources & Directory</v-col>
				</v-row>
			</v-col>

			<!-- 1.5 COLUMN -->
			<v-col class="footer-column footer-links footer-column-overlay">
				<v-row class="footer-column-row links">
					<v-col class="footer-contact">
						<!-- <router-link to=""> -->
						<v-btn disabled text x-small rounded class="contact-button-text">
							<span class="resources">Real Estate Agents/Reps</span>
						</v-btn>
						<!-- </router-link> -->
					</v-col>
				</v-row>

				<v-row class="footer-column-row links">
					<v-col class="footer-contact">
						<v-btn disabled text x-small rounded class="contact-button-text">
							<span class="resources">Real Estate Brokerages</span>
						</v-btn>
					</v-col>
				</v-row>

				<v-row class="footer-column-row links">
					<v-col class="footer-contact">
						<v-btn disabled text x-small rounded class="contact-button-text">
							<span class="resources">Real Estate & Rental Listings</span>
						</v-btn>
					</v-col>
				</v-row>

				<v-row class="footer-column-row links">
					<v-col class="footer-contact">
						<v-btn disabled text x-small rounded class="contact-button-text">
							<span class="resources">Best Supplies & Services</span>
						</v-btn>
					</v-col>
				</v-row>

				<v-row class="footer-column-row links">
					<v-col class="footer-contact">
						<v-btn disabled text x-small rounded class="contact-button-text">
							<span class="resources">Buy & Sell - Marketplace</span>
						</v-btn>
					</v-col>
				</v-row>

				<v-row class="footer-column-row links">
					<v-col class="footer-contact">
						<v-btn disabled text x-small rounded class="contact-button-text">
							<span class="resources">Municipal Service Directory</span>
						</v-btn>
					</v-col>
				</v-row>

				<v-row class="footer-column-row links">
					<v-col class="footer-contact">
						<v-btn disabled text x-small rounded class="contact-button-text">
							<span class="resources">Real Estate Related Events</span>
						</v-btn>
					</v-col>
				</v-row>

				<v-row class="footer-column-row links">
					<v-col class="footer-contact">
						<v-btn disabled text x-small rounded class="contact-button-text">
							<span class="resources">Trade/Jobs & Opportunities</span>
						</v-btn>
					</v-col>
				</v-row>
			</v-col>

			<!-- SECOND COLUMN -->
			<v-col class="footer-column feedback footer-column-overlay" style="max-width:150px !important">
				<div style="display:flex; flex-wrap:wrap; justify-content:center; max-width:150px; padding-top: 10px;">
					<!-- <v-row class="footer-column-row">
						<v-col class="footer-contact ">
							<router-link to="/policy/media">
								<v-btn text x-small rounded class="contact-button-text">Send Feedback
								</v-btn>
							</router-link>
						</v-col>
					</v-row> -->

					<!-- <v-row class="footer-column-row">
						<v-col class="footer-contact ">
							<router-link to="/policy/media">
								<v-btn text x-small rounded class="contact-button-text">Contact Us
								</v-btn>
							</router-link>
						</v-col>
					</v-row> -->

					<!-- <v-row class="footer-column-row">
						<v-col class="footer-contact ">
							<router-link to="/policy/mediaPolicy">
								<v-btn text x-small rounded class="contact-button-text">Media Policy
								</v-btn>
							</router-link>
						</v-col>
					</v-row> -->

					<v-row class="footer-column-row">
						<v-col class="footer-contact ">
							<router-link to="/policy/privacyPolicy">
								<v-btn text x-small rounded class="contact-button-text">
									Privacy Policy
								</v-btn>
							</router-link>
						</v-col>
					</v-row>

					<v-row class="footer-column-row">
						<v-col class="footer-contact ">
							<router-link to="/policy/termsAndUse">
								<v-btn text x-small rounded class="contact-button-text">
									Terms of Use
								</v-btn>
							</router-link>
						</v-col>
					</v-row>
				</div>
			</v-col>

			<!-- THIRD COLUMN -->
			<v-col class="footer-column follow-us-toplevel footer-column-overlay" style="max-width: 350px;">
				<v-row class="footer-column-row">
					<v-col class="follow-us-containter">
						<div class="follow-us">Follow us:</div>
					</v-col>
				</v-row>

				<v-row class="footer-column-row follow-social-container">
					<v-col class="follow-social-icons">
						<v-btn icon target="_blank" href="https://twitter.com/MyBREB">
							<v-icon size="30">
								mdi-twitter
							</v-icon>
						</v-btn>
					</v-col>
					<v-col class="follow-social-icons">
						<v-btn icon target="_blank" href="https://www.facebook.com/profile.php?id=100092500433436">
							<v-icon size="30">
								mdi-facebook
							</v-icon>
						</v-btn>
					</v-col>
					<v-col class="follow-social-icons">
						<v-btn icon>
							<v-icon size="30">
								mdi-linkedin
							</v-icon>
						</v-btn>
					</v-col>
					<v-col class="follow-social-icons">
						<v-btn icon target="_blank" href="https://www.instagram.com/bestrealestatebuydotcom/">
							<v-icon size="30">
								mdi-instagram
							</v-icon>
						</v-btn>
					</v-col>
				</v-row>

				<v-row class="footer-column-row">
					<v-col style="padding:5px; max-height: 10px;">
						<div style="text-align:center" class="copyright">
							Copyright © 2024 Best Real Estate Buy.
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col style="padding:5px; max-height: 10px;">
						<div style="text-align:center;" class="copyright">All rights reserved</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
import BrebLogo from "@/assets/breb.rect.logo.new.png";

export default {
	name: "PersistentFooter",
	computed: {},
	data: function () {
		return {
			BrebLogo,
		};
	},
	methods: {

	},
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables.scss";
@import "../globals";

.footer {
	justify-content: center;
	min-height: 300px;
	background: url("../assets/footerNav/toronto.png") center center/cover no-repeat;
}

.footer-column-overlay {
	z-index: 2;
}

.footer-background-color {
	min-width: 100%;
	min-height: 100%;
	position: absolute;
	top: 0;
	background-color: #0b4173af;
	z-index: 1;
}

.footer-container {
	justify-content: center;
	align-items: center;
	margin-left: 5vw;
	margin-right: 5vw;
	max-width: 1200px;
}

.footer-column {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.footer-column-row {
	width: 100%;
	margin: 0px;
}

.footer-logo-container {
	display: flex;
	width: 100%;
	justify-content: center;
}

.footer-contact {
	padding: 4px !important;
}

.contact-button-text {
	font-size: 14px;
	text-transform: capitalize;
	font-weight: 400;
	// cursor: not-allowed;
}

.follow-us-containter {
	padding: 20px 0px 0px;
}

.follow-us {
	font-size: 40px;
	text-align: center;

}

.follow-social-container {
	display: flex;
	justify-content: center;
}

.follow-social-icons {
	display: flex;
	justify-content: center;
	max-width: 50px;
}

.follow-social-icons i {
	color: #fff !important;
}

.footer-title {
	margin-top: -15px;
	font-size: 25px;
	text-align: center;
}

.resources {
	color: yellow;
	font-size: medium;
}

.copyright {
	color: #fff;
	font-size: medium;
}

@media only screen and (max-width: "1010px") {
	.footer-container {
		flex-direction: column !important;
		min-height: 850px;
		margin-bottom: 0px;
	}

	.footer-column {
		justify-content: flex-start;

	}

	.footer-logo-container {
		display: flex;
		justify-content: center;
	}

	.footer-logo-container a {
		display: flex;
	}

	.footer-logo-container img {
		width: 85%;
		margin-left: auto;
		margin-right: auto;
		height: auto;
	}

	.footer-title {
		font-size: 30px;
		text-align: center;
		padding: 0px;
		padding-top: 25px;
	}

	.footer-contact {
		padding: 0px !important;
		padding-top: 15px;
	}

	.footer-links {
		text-align: center;
		max-width: 320px !important;
		padding-left: 0px;
		// padding-top: 30px;
	}

	.links {
		max-height: 30px;
	}

	.feedback {
		max-width: 150px !important;
		max-height: 80px;
		text-align: center;
	}

	.follow-us-toplevel {
		padding-bottom: 50px;
	}

	.logo {
		max-width: 400px;
		z-index: 2;
		padding-top: 50px;
	}
}
</style>
