import axios from "axios";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    getters: {

    },
    actions: {
        async searchRealtorByOpt(ctx, { facet, start = 0, size = 15 }) {
            return axios.post(`/api/v1/user/facet/search?start=${start}&size=${size}`, facet);
        },

        async searchRealtor(ctx, { facet, start = 0, size = 15 }){
            return axios.post(`/api/v1/user/facet/search?start=${start}&size=${size}`, facet);
        },

        async getByFacetId(ctx, { id }) {
            return axios.post(`/api/v1/user/facet/${id}`);
        },

        async post(ctx, { facet }) {
            return axios.post(`/api/v1/user/facet/`, facet);
        },

        async put(ctx, { id, facet }) {
            return axios.put(`/api/v1/user/facet/${id}`, facet);
        },

        async delete(ctx, { id }) {
            return axios.delete(`/api/v1/user/facet/${id}`);
        },
    },
};