import Vue from 'vue';
import Vuex from 'vuex';
import Types from './modules/type';
import user from './modules/user';
import settings from './modules/settings';
import search from './modules/search';
import image from './modules/api/image';
import cart from './modules/cart';
import price from './modules/price';
import propertyCard from './modules/propertyCard';
import router from "@/router";
import api from "@/store/modules/api";
import location from '@/store/modules/location';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		type: Types,
		user,
		settings,
		search,
		image,
		cart,
		price,
		location,
		api: api,
		propertyCard,
	},
	getters: {
		getLocation: function () {
			return {
				lng: router.currentRoute.query.lng,
				lat: router.currentRoute.query.lat,
				name: router.currentRoute.query.name
			}
		},
		getAdvancedQuery: function (ctx) {
			
			let basic = ["lat", "lng", "name"];
			let query = Object.assign({}, ctx.route.query);
			
			basic.forEach(k => {
				if (k in query)
					delete query[k];
			})
			
			return query;
		}
	}
})
