import { StripePlugin } from '@vue-stripe/vue-stripe';

let options = {}
if(window.location.origin === 'https://www.bestrealestatebuy.com'){
	console.log("Stripe on live mode!")
	options = {
		pk: 'pk_live_51HJ0jTKvrNqDdLmflJGhCzEczFNGJLpXUacQBGJOkJ83rCoOMUAL6xQXZeqvCWoMi5bKVG23VXyYBFLfmal8P7ne005EFIhhuJ',
		testMode: false,
		locale: '',
	}
}else{
	console.log("Stripe on test mode!")
	options = {
		pk: 'pk_test_51HJ0jTKvrNqDdLmf6xPOdDNSDdMQ5MXTI80rgAn4W2BFkYHkD8Mu8V26oItDEm66KQTDDfcTiZnISGMG28qO9rvi008e2L6Z7A',
		testMode: true,
		locale: '',
	}
}

// const options = {
// 	pk: 'pk_live_51HJ0jTKvrNqDdLmflJGhCzEczFNGJLpXUacQBGJOkJ83rCoOMUAL6xQXZeqvCWoMi5bKVG23VXyYBFLfmal8P7ne005EFIhhuJ',
// 	testMode: false,
// 	// pk: 'pk_test_51HJ0jTKvrNqDdLmf6xPOdDNSDdMQ5MXTI80rgAn4W2BFkYHkD8Mu8V26oItDEm66KQTDDfcTiZnISGMG28qO9rvi008e2L6Z7A',
// 	// testMode: true,
// 	locale: '',
// }

export default {
	StripePlugin,
	options
};