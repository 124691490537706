import StripeCards from "../../stripe/StripeCards/StripeCards.vue";
import MerchantListingsList from "../../merchant/MerchantListingsList/MerchantListingsList.vue";
import {subscribtion} from "../../../services/subscribtion";

export default {
	name: "SubscribePlan",
	components: {
		StripeCards,
		MerchantListingsList,
	},
	
	data: () => {
		return {
			isModalShown: false,
			currentPlan: null,
			selectedMerchantListing: null,
			selectedPaymentMethod: null,
			
		}
	},
	
	mounted() {
	},
	
	methods: {
		show() {
			this.isModalShown = true;
		},
		
		
		getSelectedPaymentMethod() {
			return this.selectedPaymentMethod;
		},
		
		getSelectedListing() {
			return this.selectedMerchantListing
		},
		
		merchantLintingChanged(merchantListing) {
			this.selectedMerchantListing = merchantListing;
		},
		
		paymentMethodChanged(paymentMethod) {
			this.selectedPaymentMethod = paymentMethod;
		},
		
		listingsClickNext(data) {
			let selectedListing = data.selectedItem;
			
			this.$store.dispatch("api/license/obtainLicenseForListing", {
				listingId: selectedListing.id
			})
			.then((license) => {
				console.log('listingsClickNext license = ', license)
				
				if (license) {
					subscribtion.setRoutersByThis(this);
					let query = subscribtion.getUrlAllQuery();
					query.listingId = selectedListing.id;
					
					this.$helpers.routerAppendGetParameters(
						this.$router,
						this.$route,
						query,
						'subscriptionMap'
					)
				} else {
					this.$router.push({query: {listingId: selectedListing.id}});
				}
				
				this.isModalShown = false;
			})
			
			
		},
	},
	
	computed: {
		dueDate() {
			let d = new Date();
			let year = d.getFullYear();
			let month = d.getMonth();
			let day = d.getDate();
			let c = new Date(year + 1, month, day);
			let monthStr = c.toLocaleString('default', {month: 'short'});
			
			return `${day} ${monthStr} ${c.getFullYear()}`;
		},
		
		isPayDisabled() {
			
			return !(!!this.getSelectedPaymentMethod()
				&& !!this.getSelectedListing()
				&& !!this.getSelectedPaymentMethod().payment_id
				&& !!this.getSelectedListing().id);
		},
	}
}