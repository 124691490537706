import axios from "axios";
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		membership: null,
	},
	mutations: {
		setMembership(state, payload) {
			state.membership = payload;
		},
	},
	getters: {
		getMembership: function (state) {
			return state.membership;
		},
	},
	actions: {
		async search(ctx, {name, origin, distance, start = 0, size = 15}) {
			return axios.post(`/api/v1/brokerage/search?start=${start}&size=${size}`, {
				name: name,
				origin: origin,
				distance: distance,
			});
		},
		
		async searchByName(ctx, {name, start = 0, size = 15}) {
			return axios.post(`/api/v1/brokerage/search?start=${start}&size=${size}`, {
				name: name,
			});
		},
		
		async searchByLocation(ctx, {origin, start = 0, size = 15, distance = 25000}) {
			return axios.post(`/api/v1/brokerage/search?start=${start}&size=${size}`, {
				origin: origin,
				distance: distance,
			});
		},
		
		async getById(ctx, {id}) {
			return axios.get(`/api/v1/brokerage/${id}`);
		},
		
		async getMembers(ctx, {id, filter = '', start = 0, size = 12}) {
			return axios.get(`/api/v1/brokerage/${id}/members/?filter=${filter}&start=${start}&size=${size}`);
		},
		
		async post(ctx, {brokerage}) {
			return axios.post(`/api/v1/brokerage/`, brokerage);
		},
		
		async put(ctx, {id, brokerage}) {
			return axios.put(`/api/v1/brokerage/${id}`, brokerage);
		},
		
		async owned(ctx, {start = 0, size = 1000}) {
			return axios.get(`/api/v1/brokerage/owned?start=${start}&size=${size}`);
		},
		
		async passwordChange(ctx, {id, password, oldPassword}) {
			return axios.put(`/api/v1/brokerage/${id}/password`, {
				password: password,
				oldPassword: oldPassword,
			});
		},
		
		async enroll(ctx, {id, password}) {
			return axios.post(`/api/v1/brokerage/${id}/enroll`, {
				password: password,
			});
		},
		
		async leave(ctx, {id}) {
			return axios.post(`/api/v1/brokerage/${id}/leave`);
		},
		
		async delete(ctx, {id}) {
			return axios.delete(`/api/v1/brokerage/${id}`);
		},
		
		async getJoinCode(ctx, {id}) {
			return axios.get(`/api/v1/brokerage/${id}/code`);
		},
		
		async membership({commit}, {userId}) {
			return axios.get(`/api/v1/brokerage/${userId}/membership`)
			.then(r => {
				commit('setMembership', r.data);
				return r;
			});
		},
	},
};