export default {
	name: "AdListingModal",
	components: {},
	props: {
		// dialog: {
		//     type: Boolean,
		//     default: false,
		// }
	},
	
	data: () => {
		return {
			dialog: false,
		}
	},
	
	mounted() {
	},
	
	methods: {
		sendToListing() {
			this.$router.push({name: 'posting'})
			this.dialog = false;
		},
		
		show() {
			this.dialog = true;
		}
		
	},
	
	computed: {},
	
}