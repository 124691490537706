import Vue from "vue";
import axios from "./plugins/axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import stripe from './plugins/stripe'
import store from "./store";
import CountryFlag from "vue-country-flag";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import { Loader } from '@googlemaps/js-api-loader';

import VueTelInput from 'vue-tel-input';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import 'vue-tel-input/dist/vue-tel-input.css';
import Ripple from 'vuetify/lib/directives/ripple';
// Easy validation
import Vuelidate from 'vuelidate'
// Country select
import vueCountryRegionSelect from 'vue-country-region-select'
// Social Share
import VueSocialSharing from "vue-social-sharing";

import { register } from 'swiper/element/bundle'

// register Swiper custom elements
register();

// Money Format
import VuetifyMoney from 'vuetify-money';

import helpers from "@/plugins/helpers";

// Toast notifications
import Toasted from 'vue-toasted';
// Geolocation
import VueLocation from 'vue-browser-geolocation';
import './globals.scss'

// Async computed
import AsyncComputed from 'vue-async-computed'

Vue.use(AsyncComputed)

Vue.use(VueTelInput);
Vue.use(VueTelInputVuetify, {
	vuetify
});

Vue.use(Vuelidate)

Vue.use(vueCountryRegionSelect)

// Stripe
Vue.use(stripe.StripePlugin, stripe.options);

Vue.use(Toasted, {
	duration: 8000,
	position: 'top-center',
	theme: 'bubble',
	keepOnHover: true,
});

Vue.use(VueLocation);

Vue.config.productionTip = false;
Vue.use(axios, store);


Vue.component("CountryFlag", CountryFlag);

Vue.use(VuetifyMoney);
Vue.use(VueSocialSharing);

// Load google maps
const l = new Loader({
	apiKey: 'AIzaSyALsn4_R7gMx7Rq4Oe0GIOXPgrc1v1pNZ8',
	version: 'weekly',
	libraries: ["places", "drawing", "visualization"]
})

window.history.scrollRestoration = 'manual';

l.load().then((g) => {
	Vue.use(VueGoogleMaps, {
		installComponents: true,
	});

	console.log(g);

	Vue.component('GmapCluster', GmapCluster);

	Vue.use(VuetifyGoogleAutocomplete, {
		/*
			not used as loaded with component
			apiKey: key,
		  */
		vueGoogleMapsCompatibility: true,
	});

	Vue.prototype.$geocoder = new g.maps.Geocoder();
	Vue.use(helpers, store);

	new Vue({
		vuetify,
		router,
		store,
		stripe,
		data: function () {
			return {
				focus: true,
			}
		},
		render: (h) => h(App),
		directives: {
			Ripple
		}
	}).$mount("#app");

	// We load the api ourselves instead of relying on the google maps library. As such, as we need to let the vue2-google-maps library
	// know when the maps data is ready to be loaded.
	window.vueGoogleMapsInit();
})






