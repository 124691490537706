import {localStorageKeys} from "../settings/local-storage-keys";

class Subscribtion {
	
	
	constructor() {
		this.$route;
		this.$router;
	}
	
	// setters
	setRoute(route) {
		this.$route = route;
	}
	
	setRouter(router) {
		this.$router = router;
	}
	
	setRoutersByThis(that) {
		this.setRoute(that.$route);
		this.setRouter(that.$router);
	}
	
	
	setLSPlanId(planId) {
		localStorage.setItem(localStorageKeys.subscription_plan_id, planId);
	}
	
	setLSListingId(listingId) {
		localStorage.setItem(localStorageKeys.subscription_listing_id, listingId);
	}
	
	setLSBillboardsIds(billboardsIds) {
		localStorage.setItem(localStorageKeys.subscription_billboards_ids, billboardsIds);
	}
	
	setLSPlanIdFromUrl() {
		this.setLSPlanId(this.getUrlPlanId());
	}
	
	setLSListingIdFromUrl() {
		this.setLSListingId(this.getUrlListingId());
	}
	
	setLSBillboardsIdsFromUrl() {
		this.setLSBillboardsIds(this.getUrlBoardsIds());
	}
	
	setLSAllFromUrl() {
		this.setLSPlanIdFromUrl();
		this.setLSListingIdFromUrl();
		this.setLSBillboardsIdsFromUrl();
	}
	
	
	// getters
	getLSListingId() {
		let ret = localStorage.getItem(localStorageKeys.subscription_listing_id);
		return ret === "null" ? null : ret;
	}
	
	getLSPlanId() {
		let ret = localStorage.getItem(localStorageKeys.subscription_plan_id);
		return ret === "null" ? null : ret;
	}
	
	getLSBillboardsIds() {
		let ret = localStorage.getItem(localStorageKeys.subscription_billboards_ids);
		return ret === "null" ? [] : ret;
	}
	
	
	getUrlPlanId() {
		return this.$route.query.planId === 'null' ? null : this.$route.query.planId;
	}
	
	getUrlListingId() {
		return this.$route.query.listingId ? parseInt(this.$route.query.listingId) : null;
	}
	
	getUrlBoardsIds() {
		console.log('getUrlBoardsIds this.$route.query = ', JSON.stringify(this.$route.query))
		console.log('getUrlBoardsIds this.$route.query.billboards = ', JSON.stringify(this.$route.query.billboards))
		
		return this.$route.query.billboards ? this.$route.query.billboards.split(',').map(Number) : [];
	}
	
	getLSAll() {
		return {
			planId: this.getLSPlanId(),
			billboards: this.getLSBillboardsIds(),
			listingId: this.getLSListingId(),
		}
	}
	
	getUrlAll() {
		return {
			planId: this.getUrlPlanId(),
			billboards: this.getUrlBoardsIds(),
			listingId: this.getUrlListingId(),
		}
	}
	
	getUrlAllQuery() {
		return this.mapForUrlQuery(this.getUrlAll());
	}
	
	mapForUrlQuery(data) {
		return {
			...data,
			billboards: data.billboards.join(','),
		}
	}
	
	deleteLSAll() {
		this.setLSPlanId(null);
		this.setLSListingId(null);
		this.setLSBillboardsIds(null);
	}
	
	calculateUpgrade() {
	
	}
	
	
}

export const subscribtion = new Subscribtion();