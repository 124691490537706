export default {
	namespaced: true,
	state: {
		latitude: null,
		longitude: null,
		text: null,
		geolocation: null,
		services: {
			geocoding: null,
		},
	},
	getters: {
		location: (state) => {
			return {lat: state.latitude, lng: state.longitude, text: state.text}
		},
		locationEnabled: (state) => {
			return state.geolocation !== 'disabled';
		},
	},
	mutations: {
		location: function (ctx, v) {
			ctx.state.latitude = v.lat;
			ctx.state.longitude = v.lng;
			ctx.state.text = v.text;
		}
	},
	actions: {
		checkGeolocationEnabled: function() {
			return window.navigator.permissions.query({name: 'geolocation'}).then(function (result) {
				if (result.state === 'granted')
					return true;
				else if (result.state === 'denied')
					return false;
				else if (result.state === 'prompt')
					return true;
			});
		},
		searchLocation: function (ctx, {coordinates, restriction}) {
			if (ctx.state.services.geocoding == null) {
				ctx.state.services.geocoding = new window.google.maps.Geocoder();
			}
			let promise;
			
			if (coordinates)
				promise = new Promise(r => {
					return r;
				})
			else
				promise = ctx.dispatch('locate');
			
			promise.then(r => {
				coordinates = r;
				return ctx.state.services.geocoding.geocode({
					location: {lat: coordinates.latitude, lng: coordinates.longitude}
				}).then(response => {
					
					// Unpack actual content
					response = response.results;
					if (response.length > 0) {
						
						let best_match;
						switch (restriction) {
							case undefined:
							case null:
								best_match = response[0];
								break;
							default:
								best_match = response.filter(addr => {
									return addr.types.indexOf(restriction) >= 0;
								});
								if (best_match.length > 0)
									best_match = best_match[0];
								else
									return null;
							
						}
						
						if (best_match !== null) {
							ctx.state.text = best_match.formatted_address;
						}
					}
				})
			}).catch(error => {
				console.log('Unable to automatically fetch location data. ' + error)
			})
			
			
		},
		locate: function (ctx) {
			
			return window.navigator.permissions.query({name: 'geolocation'}).then(function (result) {
				if (result.state === 'granted')
					ctx.state.geolocation = 'enabled';
				else if (result.state === 'denied')
					ctx.state.geolocation = 'denied';
				else if (result.state === 'prompt')
					ctx.state.geolocation = 'prompt';
				
				return new Promise((resolve, reject) => {
					if (ctx.state.location !== 'denied') {
						window.navigator.geolocation.getCurrentPosition(success => {
							let coordinates = success.coords;
							ctx.state.latitude = coordinates.latitude;
							ctx.state.longitude = coordinates.longitude;
							resolve(coordinates);
						}, (e) => {
							ctx.state.geolocation = 'denied';
							return reject(e);
						}, {
							maximumAge: 0,
							enableHighAccuracy: true
						})
					}
				});
				
			});
		}
	}
}