import visa from 'payment-icons/min/flat/visa.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import americanExpress from 'payment-icons/min/flat/amex.svg';
import etransfer from '@/assets/payment-icons/etransfer.png';
import StripeSetUp from "@/components/stripe/StripeSetUp/StripeSetUp.vue";
import Card from "@/components/stripe/Cards.vue"

export default {
	name: "StripeCards",
	components: { StripeSetUp, Card },
	props: {
		checkboxShown: {
			type: Boolean,
			default: false
		},

		showFirstAddPayment: {
			type: Boolean,
			default: false
		},

		view: {
			type: String,
			default: 'list' // list | dropdown
		},

		returnUrl: {
			type: String,
		}
	},

	data: () => {
		return {

			cards: [],
			deleteCardId: null,
			isDeleteDialogShown: false,
			isAddPaymentMethodShown: false,
			selectedItem: null,
			hasCards: true,
			iconMap: {
				'visa': {
					icon: visa,
					name: "Visa",
					text: "VISA"
				},
				'mastercard': {
					icon: mastercard,
					name: "Mastercard",
					text: "MASTERCARD"
				},
				'amex': {
					icon: americanExpress,
					name: "American Express",
					text: "AMERICAN_EXPRESS"
				},
				'ElectronicTransfer': {
					icon: etransfer,
					name: "Electronic Transfer",
					text: "ETRANSFER"
				},
			}
		}
	},

	mounted() {
		this.getCards()
	},

	methods: {

		getCards() {
			this.$store.dispatch('api/customer/getCards').then((response) => {
				this.cards = response.data;
				if (response.data.length === 0) {
					this.hasCards = false;
				} else {
					this.hasCards = true;
					this.selectedItem = response.data[0];
					this.dropdownChange();
				}
			});
			this.$helpers.scrollToTop();
		},

		deletePaymentMethods(cardId) {
			return this.$store.dispatch(
				'api/customer/deletePaymentMethod',
				{
					paymentMethodId: cardId,
				}
			)
				.then(() => {
					this.isDeleteDialogShown = false;
					this.deleteCardId = null;
					this.getCards();
				})
				.catch((e) => {
					if (e.response && e.response.data && e.response.data.message) {
						this.$toasted.error(e.response.data.message);
					} else {
						this.$toasted.error(e);
					}
					this.isDeleteDialogShown = false;
				})
		},

		clickItem(item) {
			// console.log('clickItem item = ', item)

			if (this.selectedItem && this.selectedItem.payment_id === item.payment_id) {
				this.selectedItem = null;
			} else {
				this.selectedItem = item;
			}

			this.$emit('selectedItemChanged', this.selectedItem)
		},

		dropdownChange() {
			// console.log('dropdownChange this.selectedItem = ', this.selectedItem)
			this.$emit('selectedItemChanged', this.selectedItem)
		},

		getSelectedItem() {
			return this.selectedItem
		},

		isItemSelected(item) {
			return this.selectedItem !== null && this.selectedItem.payment_id === item.payment_id
		},

		clickAddPaymentMethod() {
			this.isAddPaymentMethodShown = true;
		},

		closeAddPaymentMethodDialog() {
			this.isAddPaymentMethodShown = false;
		}
	},

	computed: {
		mobile() {
			return this.$vuetify.breakpoint.mobile
		},
	},
}