import axios from 'axios';
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		prices: {},
		pricesLoaded: false,
		coupon: {
			multiplier: 1.0
		}
	},
	actions: {
		retrievePrices: function (ctx) {
			return axios.get('/api/v1/stripe/prices/').then(r => {
				if (r.status === 200) {
					let data = r.data;
					let prices = {};
					
					data.forEach(p => {
						Vue.set(prices, `${p.tier}.${p.sponsor}`, p)
					});
					
					ctx.commit('setPrices', prices);
					ctx.commit('setPricesLoaded', true)
				}
				
				return r.data;
			}).catch(e => {
				Vue.toasted.show('Unable to fetch price information, ' + e);
			})
		},
		requestCoupon: async function (ctx, code) {
			return axios.get(`/api/v1/stripe/coupon/${code}`).then(r => {
				if (r.status === 200)
					ctx.commit('setCoupon', r.data);
				
				return r;
			});
		}
	},
	mutations: {
		setPrices: function (ctx, prices) {
			Object.assign(ctx.prices, prices);
		},
		setPricesLoaded: function (ctx, loaded) {
			ctx.pricesLoaded = loaded;
		},
		setCoupon: function (ctx, coupon) {
			Object.assign(ctx.coupon, coupon);
		}
	},
	getters: {
		prices: state => state.prices,
		pricesLoaded: state => state.pricesLoaded,
		coupon: state => state.coupon
	}
}