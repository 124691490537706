import Stripe from "../../../plugins/stripe";

export default {
	name: "StripeSetUp",
	components: {},
	props: {
		returnUrl: {
			type: String,
			default: () => {
				return '';
			}
		},
		closeDialog: {
			type: Function
		},
		getCards: {
			type: Function
		},
		cards: {
			type: Array
		},
	},

	data: () => {
		return {
			pk: Stripe.options.pk,

			client_secret: null,
			intent_id: null,

			stripe: null,
			elements: null,

			loading: false,
			params: null,
		}
	},

	mounted() {
		this.initSetup();
	},
	beforeDestroy() {

	},
	methods: {
		initSetup() {
			this.$store.dispatch('api/customer/setup')
				.then((res) => {
					this.client_secret = res.data.client_secret;
					this.intent_id = res.data.intent_id;

					this.initStripe();
				});
			this.params = {
				redirect: this.returnUrl ? 'always' : 'if_required',
				confirmParams: {
					return_url: this.returnUrl,
				}
			}
		},

		initStripe() {
			this.stripe = window.Stripe(this.pk);

			const options = {
				clientSecret: this.client_secret,
				// Fully customizable with appearance API.
				appearance: {
					redirect: 'if_required',
				},
			};

			// Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
			this.elements = this.stripe.elements(options);

			// Create and mount the Payment Element
			const paymentElement = this.elements.create('payment');
			paymentElement.mount('#payment-element');
		},

		async submit() {
			this.loading = true;

			/**
			 * @type Stripe
			 */
			let stripe = this.stripe;

			const { error } = await stripe.confirmSetup({
				//`Elements` instance that was used to create the Payment Element
				elements: this.elements,
				...this.params,
			});

			if (error) {
				// This point will only be reached if there is an immediate error when
				// confirming the payment. Show error to your customer (for example, payment
				// details incomplete)
				const messageContainer = document.querySelector('#error-message');
				messageContainer.textContent = error.message;
			} else {
				// Your customer will be redirected to your `return_url`. For some payment
				// methods like iDEAL, your customer will be redirected to an intermediate
				// site first to authorize the payment, then redirected to the `return_url`.
			}

			setTimeout(() => {
				this.$emit('getCards', null);
				this.loading = false;
				this.closeDialog();
			}, 4000);
		},
		cancel() {
			this.closeDialog();
		}
	},

	computed: {},
}