import LocationAutocomplete from "@/components/geography/LocationAutocomplete";

export default {
	name: "SearchBillboard",
	components: {LocationAutocomplete},
	props: {
		location: {},
		mapComponent: null,
	},
	data: function () {
		let location = this.location ? this.location : {}
		return {
			dataQuery: {
				lat: location.latitude,
				lng: location.longitude,
				name: location.geolocationName,
			},
			map: null,
		}
	},
	computed:{
		mobile() {
            return this.$vuetify.breakpoint.mobile
        },
        cellphone() {
            return this.$vuetify.breakpoint.xs
        },
	},
	methods: {
		
		clusterCalculator(markers, numStyles) {
			
			let index = 0;
			// let title = "";
			let count = markers.length.toString();
			
			let dv = count;
			while (dv !== 0) {
				dv = parseInt(dv / 10, 10);
				index++;
			}
			
			index = Math.min(index, numStyles);
			return {
				text: count,
				index: index,
				title: count
			};
		},
		
		placeChanged(l) {
			if (!l) {
				return;
			}
			
			this.dataQuery.name = l.formatted_address
			
			let g = l.geometry;
			let viewport = g.viewport.toJSON();
			let data = {
				location: {
					lng: g.location.lng(),
					lat: g.location.lat(),
				},
				viewport: {
					// DO not remove
					south: viewport.south,
					west: viewport.west,
					north: viewport.north,
					east: viewport.east,
					
					
					// Ra: {
					//     hi: g.viewport.Sa.hi,
					//     lo: g.viewport.Sa.lo,
					// },
					// wb: {
					//     hi: g.viewport.vb.hi,
					//     lo: g.viewport.vb.lo,
					// }
					
					// DO not remove
					// Ra: {
					//     hi: g.viewport.Ra.hi,
					//     lo: g.viewport.Ra.lo,
					// },
					// wb: {
					//     hi: g.viewport.wb.hi,
					//     lo: g.viewport.wb.lo,
					// }
				}
			};
			
			let v = data.viewport;
			let latLngBoundsLiteral = {
				// DO not remove
				north: v.north,
				east: v.east,
				south: v.south,
				west: v.west,
				
				// DO not remove
				// north: v.wb.hi,
				// east: v.Ra.hi,
				// south: v.wb.lo,
				// west: v.Ra.lo,
			}
			// DO not remove
			// console.log('placeChanged latLngBoundsLiteral = ', JSON.stringify(latLngBoundsLiteral));
			// console.log('placeChanged latLngBoundsLiteral = ', latLngBoundsLiteral);
			
			let boundsWithBillboard = this.mapComponent.getBoundsWithBillboard(latLngBoundsLiteral, data.location)
			
			// DO not remove
			// console.log('placeChanged v = ', JSON.stringify(v));
			// console.log('placeChanged data = ', JSON.stringify(data));
			// console.log('placeChanged data.location = ', JSON.stringify(data.location));
			// console.log('placeChanged boundsWithBillboard = ', JSON.stringify(boundsWithBillboard));
			
			this.mapComponent.map.fitBounds(boundsWithBillboard);
			this.mapComponent.zoomUpdate();
		},
	},
}