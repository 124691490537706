import BrebAsyncImage from "@/components/accessory/BrebImage";

export default {
	name: "MerchantListingsList",
	components: {BrebAsyncImage},
	
	data: () => {
		return {
			selectedItem: null,
		}
	},
	
	mounted() {
		this.$store.dispatch('api/listings/requestSelfListingsAll');
	},
	
	methods: {
		clickItem(item) {
			if (this.isItemSelected(item)) {
				this.selectedItem = null;
			} else {
				this.selectedItem = item;
			}
			
			this.$emit('selectedItemChanged', this.selectedItem);
			
			this.clickNext();
		},
		
		isItemSelected(item) {
			return this.selectedItem && this.selectedItem.id === item.id;
		},
		
		getSelectedItem() {
			return this.selectedItem;
		},
		
		clickNext() {
			// this.$router.push({ query: { listingId: this.selectedItem.id }})
			this.$emit('clickNext', {
				selectedItem: this.selectedItem
			})
		}
	},
	
	computed: {
		listings() {
			return this.$store.getters['api/listings/getSelfListingsMerchant']
		},
		
		isClickNextDisabled() {
			return !(this.selectedItem && this.selectedItem.id);
		},
	},
}