import store from "../../../store/index.js";

export default {
	namespaced: true,
	state: {},
	mutations: {},
	getters: {},
	actions: {
		async getById(ctx, {id}) {
			return new Promise((resolve, reject) => {
				let membership = store.dispatch('api/brokerage/membership',
					{
						userId: id,
					});
				
				membership.then((response) => {
					let brokerageId = response.data.id;
					store.dispatch('api/brokerage/getMembers', {
						id: brokerageId
					}).then((response) => {
						let brokers = response.data.results;
						
						let broker = brokers.find((broker) => {
							return broker.id === id;
						});
						
						if (broker) {
							resolve({
								data: broker
							});
						} else {
							reject("can't find the broker");
						}
					});
				});
			});
			// return axios.get(`/api/v1/user/${id}`);
		},
	},
};