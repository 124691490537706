import axios from "axios";

export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    getters: {

    },
    actions: {
        async getSettingByKey(ctx, { key }) {
            return axios.get(`/api/v1/settings/${key}`);
        },

        async setSettingByKey(ctx, { key, body }) {
            return axios.post(`/api/v1/settings/${key}`, body, {
                headers: {
                    'Content-Type': 'text/plain'
                }
            });
        },
    },
};