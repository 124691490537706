import axios from "axios";

export default {
	namespaced: true,
	state: {},
	mutations: {},
	getters: {},
	actions: {
		async getCards() {
			return axios.get(`/api/v1/customer/cards`);
		},
		
		async deletePaymentMethod(ctx, {paymentMethodId}) {
			return axios.delete(`/api/v1/customer/${paymentMethodId}`);
		},
		
		async setup() {
			return axios.post(`/api/v1/customer/setup`);
		},
	},
};