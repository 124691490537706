import axios from "axios";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {

    },
    actions: {
        async getSelfInvoices(ctx, { shopId, page = 0, size = 15 }) {
            return axios.get(`/api/v1/marketplace/shop/${shopId}/invoices/?page=${page}&size=${size}`);
        },
        async getInvoice(ctx, { invoiceId }) {
            return axios.get(`/api/v1/marketplace/invoice/${invoiceId}`);
        },
        async requestRefund(ctx, { request }) {
            return axios.post(`/api/v1/marketplace/invoice/${request.invoiceId}/refund`);
        },
    },
    getters: {

    },
    mutations: {

    }
};
