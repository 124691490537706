import listings from './api/listings';
import images from './api/image';
import billboard from './api/billboard';
import brokers from './api/brokers';
import brokerage from "./api/brokerage";
import inbox from "./api/inbox";
import bookmarks from "./api/bookmarks";
import customer from "./api/customer";
import license from "./api/license";
import partner from "./api/partner";
import facet from "./api/facet";
import systemSetting from './api/systemSetting';
import shop from './api/shop';
import product from './api/product';
import cart from './api/cart';
import order from './api/order';
import invoice from './api/invoice';
import checkout from './api/checkout';
import rule from './api/rule';
import acl from './api/acl';

export default {
	namespaced: true,
	modules: {
		listings,
		images,
		billboard,
		brokers,
		brokerage,
		inbox,
		bookmarks,
		customer,
		license,
		partner,
		facet,
		systemSetting,
		shop,
		product,
		cart,
		order,
		invoice,
		checkout,
		rule,
		acl,
	}
}