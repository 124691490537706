/* eslint-disable */
import axios from "axios";
import Vue from 'vue';

export default {
	namespaced: true,
	state: {
		unreadCount: null,
		ignored: [],
		unread: {},
	},
	
	mutations: {
		setMembership(state, payload) {
			state.membership = payload;
		},
		
		setUnreadCount(state, payload) {
			state.unreadCount = payload;
		},
		
		setUnread(state, payload) {
			state.unread = payload;
		},
		
		setIgnored(state, payload) {
			state.ignored = payload;
		},
	},
	
	getters: {
		getMembership: function (state) {
			return state.membership;
		},
		
		getUnreadCount: function (state) {
			return state.unreadCount;
		},
		
		getUnread: function (state) {
			return state.unread;
		},
		
		getIgnored: function (state) {
			return state.ignored;
		},
	},
	
	actions: {
		async postMessageToUser(ctx, {userId, body}) {
			return axios.post(`/api/v1/inbox/${userId}`, {
				body: body,
			});
		},
		
		async postMessage(ctx, {threadId, body}) {
			return axios.post(`/api/v1/inbox/thread/${threadId}`, {
				body: body,
			});
		},
		
		async getMessages(ctx, {threadId, start = 0, size = 2}) {
			return axios.get(`/api/v1/inbox/thread/${threadId}/messages?start=${start}&size=${size}`);
		},
		
		/**
		 * @param {Array<Number>} usersId
		 * @returns {Promise<any>}
		 */
		async postThread({}, usersId) {
			return axios.post(`/api/v1/inbox/thread`, usersId);
		},
		
		async unIgnore(ctx, {userId}) {
			return axios.post(`/api/v1/inbox/${userId}/unignore`);
		},
		
		async requestIgnored(ctx) {
			return axios.get(`/api/v1/inbox/ignored`).then((response) => {
				ctx.commit('setIgnored', response.data);
			});
		},
		
		async ignore(ctx, {userId}) {
			return axios.post(`/api/v1/inbox/${userId}/ignore`);
		},
		
		async getThread(ctx, {threadId}) {
			return axios.get(`/api/v1/inbox/thread/${threadId}`);
		},
		
		async getThreads(ctx, {start = 0, size = 25}) {
			return axios.get(`/api/v1/inbox/threads?start=${start}&size=${size}`);
		},
		
		async deleteThread(ctx, {threadId}) {
			return axios.delete(`/api/v1/inbox/thread/${threadId}`);
		},
		
		async requestUnreadCount(ctx) {
			return axios.get(`/api/v1/inbox/unread/count`).then((response) => {
				ctx.commit('setUnreadCount', response.data);
			});
		},
		
		async requestUnread(ctx) {
			return axios.get(`/api/v1/inbox/unread`).then((response) => {
				ctx.commit('setUnread', response.data);
			});
		},
		
		async markAsRead(ctx, {threadId}) {
			return axios.post(`/api/v1/inbox/${threadId}/read`);
		},
		
	},
};