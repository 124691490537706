import Vue from "vue";
import Vuetify from "vuetify/lib";
import VuetifyConfirm from "vuetify-confirm";
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify);

const vuetify = new Vuetify({
	theme: {
		light: true,
		themes: {
			light: {
				primary: {
					base: "#072d52",
					text: "#FFFFFF",
				},
				secondary: "#b5a647",
				accent: "#0b4073",
				text: "#FFFFFF",
			},
		},
		options: {
			variations: true,
			customProperties: true,
		},
	},
	
	icons: {
		iconfont: "md",
	},
});

Vue.use(VuetifyConfirm, {vuetify});

export default vuetify;
