import axios from "axios";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        shops: [],
    },
    actions: {
        async queryShop(ctx, { shopId }) {
            return axios.get(`/api/v1/marketplace/shop/${shopId}`).then(result => {
                if (result.data) {
                    return result.data;
                }
                return null;
            });
        },
        async getShops(ctx) {
            return axios.get('/api/v1/marketplace/shop/', {
                params: {
                    page: 0,
                    size: 100,
                }
            }).then(async result => {
                let activeShops = result.data.content.filter(shop => shop.active === true);
                if (activeShops && activeShops.length > 0) {
                    ctx.commit('setShops', activeShops);
                } else {
                    ctx.commit('setShops', []);
                }
                return activeShops;
            });
        },
        async getShopSetting(ctx, { shopId }) {
            return axios.get(`/api/v1/marketplace/shop/${shopId}/settings`)
        },
        async createShop(ctx, { shop }) {
            return axios.post(`/api/v1/marketplace/shop/`, shop).then(result => {
                if (result.data) {
                    ctx.commit('setShops', [...ctx.getters.getShops, result.data]);
                } else {
                    ctx.commit('setShops', []);
                }
                return result.data;
            });
        },
        async updateShop(ctx, { id, shop }) {
            return axios.put(`/api/v1/marketplace/shop/${id}`, shop).then(result => {
                if (result.data) {
                    ctx.dispatch('getShops');
                } else {
                    ctx.commit('setShops', []);
                }
                return result.data;
            });
        },
        async deleteShop(ctx, { id }) {
            return axios.delete(`/api/v1/marketplace/shop/${id}`).then(result => {
                ctx.dispatch('getShops');
            });
        },
        async attachPaymentMethod(ctx, { shopId, stripeAccountId }) {
            return axios.post(`/api/v1/marketplace/shop/${shopId}/manage/payment/stripe`, { account_id: stripeAccountId });
        },
        //shipping rate
        async getShippingRateList(ctx, { shopId }) {
            return axios.get(`/api/v1/marketplace/shop/${shopId}/shipping?active=${true}`).then(result => {
                return result.data;
            });
        },
        async createShippingRate(ctx, { shopId, rate }) {
            return axios.post(`/api/v1/marketplace/shop/${shopId}/shipping`, rate).then(result => {
                return result.data;
            });
        },
        async updateShippingRate(ctx, { shopId, rateId, request }) {
            return axios.put(`/api/v1/marketplace/shop/${shopId}/shipping/${rateId}`, request).then(res => {
                return res.data;
            });
        },

    },
    getters: {
        getShops(state) {
            return state.shops;
        },
    },
    mutations: {
        setShops(state, payload) {
            state.shops = payload;
        },
    }
};
